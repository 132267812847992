import AuthMiddleware from "@/middleware/auth"

const PhlebioOrders = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/AllPhlebioOrders"
  )
const PhlebioOrderDetails = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/PhlebioOrderView"
  )
const UpdatePhlebioOrderDetails = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/UpdatePhlebioOrderDetails"
  )
const PhlebioRequests = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/AllPhlebioRequests"
  )
const PhlebioCreateAccession = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/PhlebioAccession"
  )
const EditPhlebioOrderDetails = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/PhlebioOrderEdit"
  )
const PhlebioLabsquireOrders = () =>
    import(
      /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/PhlebioLabsquireOrders"
  )  
const PhlebioEMROrders = () =>
    import(
      /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/AllPhlebioRequests"
  )  
const PhlebioRouter = [
  {
    path: "/phlebio-orders",
    name: "AllPhlebioOrders",
    component: PhlebioOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/requests",
    name: "AllPhlebioRequests",
    component: PhlebioRequests,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/:phlebio_id",
    name: "PhlebioOrderDetails",
    component: PhlebioOrderDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/update-phlebio-orders/:phlebio_id",
    name: "UpdatePhlebioOrderDetails",
    component: UpdatePhlebioOrderDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/:phlebio_id/create-accession",
    name: "Create-Accession",
    component: PhlebioCreateAccession,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "CREATE ACCESSION",
    },
  },
  {
    path: "/phlebio-orders/:phlebio_id/edit",
    name: "EditPhlebioOrderDetails",
    component: EditPhlebioOrderDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "CREATE ACCESSION",
    },
  },
  {
    path: "/phlebio-orders/phlebio",
    name: "PhlebioEMROrders",
    component: PhlebioEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/labsquire-orders",
    name: "PhlebioLabsquireOrders",
    component: PhlebioLabsquireOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
]

export { PhlebioRouter }
